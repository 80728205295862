import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import CookieBot from 'react-cookiebot';
import UpdateAccountPrompt from './common/UpdateAccountPrompt';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// PLEASE DO NOT PUT ANTD STYLING SHEET HERE AS OVERRIDES EXISTING STYLES

import styled from 'styled-components';
import moment from 'moment';

import { checkAuth } from '../actions/authAction';
import { checkBrowserWidth } from '../actions/checkBrowserWidth';

import ScrollToTop from '../helpers/ScrollToTop';

import * as theme from '../theme';

// PAGES
import CreateSession from './pages/CreateSession';
import Login from './pages/Login';
import UserDashboard from './pages/ParticipantDashboard';
import Dashboard from './pages/Dashboard';
import Home from './pages/LandingPage';
import SignUp, { ParticipantSignUp } from './pages/SignUp';
import UserResults from './pages/UserResults';
import AddTrainer from './pages/AddTrainer';
import Survey from './pages/survey/index';
import TrainerListPage from './pages/TrainerListPage';
import Participants from './pages/Participants';
import ViewSessions from './pages/ViewSessions';
import SessionDetails from './pages/SessionDetails';
import EditSession from './pages/SessionDetails/SessionActions/SessionEdit';
import SurveyResults from './pages/SurveyResults';
import ChangePassword from './pages/ChangePassword';
import ParticipantProgress from './pages/ParticipantProgress';
import Certificate from './pages/Certificate';
// import AdminDemographic from './pages/AdminDemographic';
// import DecideView from './pages/DecideView';
import ThankYouPage from './pages/ThankYouPage';
import TrainerThankYou from './pages/TrainerThankYou';
import ForgetPassword from './pages/ForgetPassword';
import ResetPassword from './pages/ForgetPassword/ResetPassword';

import SessionRegistration from './pages/SessionRegistration';

import MyProfile from './pages/MyProfile';
import LocalLeadsAndManagersList from './pages/LocalLeadsAndManagersList';
import ConfirmTrainerRemoval from './pages/ConfirmTrainerRemoval';
import PrintSessionDetails from './pages/SessionDetails/PrintSessionDetails';
import NotFound from './pages/ErrorPages/404';
import ServerError from './pages/ErrorPages/500';
import Unauthorized from './pages/ErrorPages/403';
import ParticipantSessions from './pages/ParticipantSessions';
import SessionCreated from './pages/CreateSession/SessionCreated';
import MyAccount from './pages/MyAccount';
import TrainerSessions from './pages/TrainerSessions';
import DecideRole from './pages/DecideRole';
import ChooseCourse from './pages/ChooseCourse';
import TrainerAdvice from './pages/TrainerAdvice';
import HowItWorks from './pages/TrainerSignupSteps/HowItWorks';
import TrainerThankYouPage from './pages/TrainerSignupSteps';
import CertifiedTrainer from './pages/TrainerSignupSteps/CertifiedTrainer';
import ChooseCourseForMaterials from './pages/ChooseCourseForMaterials';

// COMPONENTS
import PrivateRoute from './common/PrivateRoute';

// ROUTES
import {
  HOME_URL,
  DASHBOARD_URL,
  SURVEY_URL,
  LOGIN_URL,
  SIGN_UP_URL,
  TRAINERS_URL,
  PARTICIPANTS_URL,
  TRAINER_RESULTS_URL,
  TRAINER_SESSIONS_URL,
  GROUP_SESSIONS_URL,
  SESSION_DETAILS_URL,
  FORGET_PASSWORD,
  MY_PROFILE_URL,
  ALL_SESSIONS_URL,
  MY_SESSIONS_URL,
  TRAINER_MANAGERS_GROUPS,
  REMOVE_TRAINER_FROM_GROUP,
  PARTICIPANT_SESSIONS_LIST,
  PARTICIPANT_SIGN_UP_URL,
  SESSION_CREATED_URL,
  REGISTER_URL,
  PARTICIPANT_FILLED_SURVEY_SUCCESSFULLY,
  TRAINER_PUBLIC_SESSIONS,
  PARTICIPANT_LOGIN,
  PARTICIPANT_SESSION_DETAILS,
  PARTICIPANT_MATERIALS,
  MY_ACCOUNT_URL,
  DECIDE_ROLE,
  TRAINER_ADVICE_URL,
  TRAINER_FILLED_TRAINER_SURVEY_SUCCESSFULLY,
  HOW_IT_WORKS,
  TRAINER_TTT_SIGN_UP,
  CERTIFIED_TRAINER,
  CHOOSE_RESULTS_FOR_COURSE,
  CHOOSE_RESULTS_FOR_MATERIALS,
  EVAL_RESULTS
} from '../constants/navigationRoutes';
import { userRoles } from '../constants';

import StoryBook from './pages/StoryBook';
import ParticipantSessionDetails from './pages/ParticipantSessionDetails';
import ParticipantMaterials from './pages/ParticipantMaterials';

const domainGroupId = process.env.REACT_APP_COOKIEBOT_DOMAIN_ID;

moment.updateLocale('en', {
  week: {
    dow: 1
  }
});

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: ${theme.colors.offWhite};
`;

class App extends Component {
  state = {
    width: window.innerWidth
  };

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentDidMount() {
    const {
      checkAuth: checkAuthActionCreator,
      checkBrowserWidth: checkBrowserWidthActionCreator
    } = this.props;
    const { width } = this.state;
    const isMobile = width <= 700;
    const isDeskTop = width >= 700;

    const data = {
      width,
      isMobile,
      isDeskTop
    };
    checkAuthActionCreator();
    checkBrowserWidthActionCreator(data);
  }

  componentDidUpdate() {
    const { checkBrowserWidth: checkBrowserWidthActionCreator } = this.props;

    const { width } = this.state;
    const isMobile = width <= 700;
    const isDeskTop = width >= 700;

    const data = {
      width,
      isMobile,
      isDeskTop
    };

    checkBrowserWidthActionCreator(data);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    return (
      <Wrapper id="wrapper">
        <ThemeProvider theme={theme}>
          <Router>
            <ScrollToTop />
            <UpdateAccountPrompt />
            <Switch>
              {process.env.NODE_ENV === 'development' && (
                <Route path="/storybook" component={StoryBook} />
              )}
              <PrivateRoute
                exact
                path="/print/:sessionId"
                Component={PrintSessionDetails}
                allowedRoles={['trainer', 'admin', 'localLead']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={DECIDE_ROLE}
                Component={DecideRole}
                isPrivate
                decideRole
              />

              <PrivateRoute
                exact
                path={CHOOSE_RESULTS_FOR_COURSE}
                Component={ChooseCourse}
                allowedRoles={['trainer', 'admin', 'localLead']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={CHOOSE_RESULTS_FOR_MATERIALS}
                Component={ChooseCourseForMaterials}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={EVAL_RESULTS}
                Component={SurveyResults}
                allowedRoles={['trainer', 'admin', 'localLead']}
                isPrivate
              />
              <PrivateRoute exact path={HOME_URL} component={Home} publicOnly />
              <PrivateRoute
                exact
                path={PARTICIPANT_FILLED_SURVEY_SUCCESSFULLY}
                component={ThankYouPage}
                isPrivate
                allowedRoles={['participant']}
              />
              <PrivateRoute
                exact
                path={TRAINER_FILLED_TRAINER_SURVEY_SUCCESSFULLY}
                component={TrainerThankYou}
                isPrivate
                allowedRoles={['trainer', 'admin', 'localLead']}
              />

              <PrivateRoute
                exact
                path="/reset-password/:token"
                component={ResetPassword}
                publicOnly
              />
              <PrivateRoute
                exact
                path={TRAINER_RESULTS_URL}
                Component={UserResults}
                allowedRoles={['trainer', 'admin', 'localLead']}
                isPrivate
              />

              <PrivateRoute
                exact
                path={MY_ACCOUNT_URL}
                Component={MyAccount}
                allowedRoles={['trainer', 'admin', 'localLead', 'participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={TRAINER_ADVICE_URL}
                Component={TrainerAdvice}
                allowedRoles={['trainer', 'localLead']}
                isPrivate
              />

              <PrivateRoute
                exact
                path="/add-trainer"
                Component={AddTrainer}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={DASHBOARD_URL}
                Component={Dashboard}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />
              <PrivateRoute
                exact
                path="/change-password"
                Component={ChangePassword}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={TRAINERS_URL}
                Component={TrainerListPage}
                allowedRoles={['admin', 'localLead']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={PARTICIPANTS_URL}
                Component={Participants}
                allowedRoles={['admin']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={SESSION_DETAILS_URL}
                Component={SessionDetails}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />
              {/* <PrivateRoute
              exact
              path={DECIDE_VIEW_URL}
              Component={DecideView}
              isAuthenticated={isAuthenticated}
              loaded={loaded}
              allowedRoles={['admin', 'localLead']}
              role={role}
              navbar
            />
          */}
              <PrivateRoute
                exact
                path="/create-session"
                Component={CreateSession}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />

              <PrivateRoute
                exact
                path={SESSION_CREATED_URL}
                Component={SessionCreated}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />
              <PrivateRoute exact path={SURVEY_URL} component={Survey} />
              <PrivateRoute
                exact
                path="/session-edit/:id"
                Component={EditSession}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />
              {/* <PrivateRoute
                path="/demographics"
                Component={AdminDemographic}
                isAuthenticated={isAuthenticated}
                loaded={loaded}
                allowedRoles={['admin']}
                role={role}
              /> */}

              <PrivateRoute
                path={MY_PROFILE_URL}
                Component={MyProfile}
                allowedRoles={['admin', 'localLead', 'trainer']}
                isPrivate
              />

              <PrivateRoute
                path={TRAINER_MANAGERS_GROUPS}
                Component={LocalLeadsAndManagersList}
                allowedRoles={['trainer']}
                isPrivate
              />

              <PrivateRoute
                exact
                path={LOGIN_URL}
                Component={Login}
                publicOnly
              />
              <PrivateRoute
                exact
                path={FORGET_PASSWORD}
                component={ForgetPassword}
                publicOnly
              />

              <PrivateRoute exact path={SIGN_UP_URL} component={SignUp} />
              <PrivateRoute
                exact
                path={PARTICIPANT_SIGN_UP_URL}
                component={ParticipantSignUp}
                publicOnly
              />
              <PrivateRoute
                path={PARTICIPANT_SESSION_DETAILS}
                Component={ParticipantSessionDetails}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                path={PARTICIPANT_MATERIALS}
                Component={ParticipantMaterials}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={PARTICIPANT_LOGIN}
                Component={Login}
                publicOnly
                loginRole={userRoles.participant}
              />
              <PrivateRoute
                exact
                path="/participant-dashboard"
                Component={UserDashboard}
                allowedRoles={['participant']}
                isPrivate
              />

              <PrivateRoute
                exact
                path="/participant/progress"
                Component={ParticipantProgress}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path="/certificate/:sessionId"
                component={Certificate}
                isPrivate
              />

              <PrivateRoute
                exact
                path={ALL_SESSIONS_URL}
                Component={ViewSessions}
                allowedRoles={['trainer', 'localLead', 'admin']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={MY_SESSIONS_URL}
                Component={ViewSessions}
                allowedRoles={['trainer', 'localLead', 'admin']}
                isPrivate
              />
              <PrivateRoute exact path={HOW_IT_WORKS} component={HowItWorks} />
              <PrivateRoute
                exact
                path={TRAINER_SESSIONS_URL}
                Component={ViewSessions}
                allowedRoles={['trainer', 'localLead', 'admin']}
                isPrivate
              />

              <PrivateRoute
                exact
                path={GROUP_SESSIONS_URL}
                Component={ViewSessions}
                allowedRoles={['localLead', 'admin']}
                isPrivate
              />

              <PrivateRoute
                exact
                path={PARTICIPANT_SESSIONS_LIST}
                Component={ParticipantSessions}
                allowedRoles={['participant']}
                isPrivate
              />

              <PrivateRoute
                exact
                path={REGISTER_URL}
                component={SessionRegistration}
              />

              <PrivateRoute
                exact
                path={TRAINER_PUBLIC_SESSIONS}
                component={TrainerSessions}
              />

              <PrivateRoute
                exact
                path={REMOVE_TRAINER_FROM_GROUP}
                component={ConfirmTrainerRemoval}
                publicOnly
              />

              <PrivateRoute
                exact
                path={TRAINER_TTT_SIGN_UP}
                component={TrainerThankYouPage}
              />
              <PrivateRoute
                exact
                path={CERTIFIED_TRAINER}
                component={CertifiedTrainer}
              />

              <PrivateRoute exact path={HOW_IT_WORKS} component={HowItWorks} />

              <Route path="/404err" render={() => <NotFound />} />
              <Route path="/500err" render={() => <ServerError />} />
              <Route path="/unauthorized" render={() => <Unauthorized />} />
              <Route render={() => <NotFound />} />
            </Switch>
          </Router>
          {process.NODE_ENV === 'production' && (
            <CookieBot domainGroupId={domainGroupId} />
          )}
        </ThemeProvider>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  id: state.auth.userId,
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  loaded: state.auth.loaded
});

export default connect(mapStateToProps, {
  checkAuth,
  checkBrowserWidth
})(Sentry.withProfiler(App));
