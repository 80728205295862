import axios from 'axios';
import { message } from 'antd';
import Swal from 'sweetalert2';
import { customSwal } from '../theme';

import {
  FETCH_ALL_SESSIONS,
  FETCH_TRAINERS_SESSIONS,
  FETCH_LOCAL_LEAD_SESSIONS,
  FETCH_SESSION_DETAILS,
  DELETE_SESSION_SUCCESS,
  EDIT_SESSION_SUCCESS,
  UPDATE_EMAILS_SUCCESS,
  SEND_SURVEY_EMAIL_SUCCESS,
  FETCH_PARTICIPANT_SESSIONS_SUCCESS,
  FETCH_PARTICIPANT_SESSIONS_BY_EMAIL_SUCCESS,
  LOADING_FALSE,
  LOADING_TRUE
} from '../constants/actionTypes';

import { MY_SESSIONS_URL } from '../constants/navigationRoutes';

export const fetchTrainerSessions = ({
  id,
  history,
  page,
  type,
  sortAscending
}) => async dispatch => {
  dispatch({ type: LOADING_TRUE, payload: 'fetchSessions' });

  axios
    .get(`/api/users/trainer-sessions/${id}`, {
      params: { page, type, sortAscending }
    })
    .then(res =>
      dispatch({
        type: FETCH_TRAINERS_SESSIONS,
        payload: { data: res.data, type }
      })
    )
    .catch(error => {
      history.push('/404err');
    })
    .finally(() => {
      dispatch({ type: LOADING_FALSE, payload: 'fetchSessions' });
    });
};

export const fetchLocalLeadSessions = ({
  id,
  history,
  page,
  type,
  sortAscending
}) => async dispatch => {
  dispatch({ type: LOADING_TRUE, payload: 'fetchSessions' });

  axios
    .get(`/api/users/sessions/${id}`, { params: { page, type, sortAscending } })
    .then(res =>
      dispatch({
        type: FETCH_LOCAL_LEAD_SESSIONS,
        payload: { data: res.data, type }
      })
    )
    .catch(() => {
      history.push('/404err');
    })
    .finally(() => {
      dispatch({ type: LOADING_FALSE, payload: 'fetchSessions' });
    });
};

export const fetchALLSessions = ({
  history,
  page,
  type,
  sortAscending
}) => async dispatch => {
  dispatch({ type: LOADING_TRUE, payload: 'fetchSessions' });
  axios
    .get(`/api/users/sessions`, { params: { page, type, sortAscending } })
    .then(res =>
      dispatch({
        type: FETCH_ALL_SESSIONS,
        payload: { data: res.data, type }
      })
    )
    .catch(() => history.push('/404err'))
    .finally(() => {
      dispatch({ type: LOADING_FALSE, payload: 'fetchSessions' });
    });
};

export const fetchSessionDetails = (id, history) => async dispatch => {
  axios
    .get(`/api/session-details/${id}`)
    .then(res =>
      dispatch({
        type: FETCH_SESSION_DETAILS,
        payload: res.data
      })
    )
    .catch(() => history.push('/404err'));
};

export const deleteSessionAction = (id, history) => async dispatch => {
  axios
    .delete(`/api/session-delete/${id}`)
    .then(res =>
      dispatch({
        type: DELETE_SESSION_SUCCESS,
        payload: res.data
      })
    )
    .then(() => {
      message.success(`Success! Session has been successfully deleted`);
      history.push(MY_SESSIONS_URL);
    })
    .catch(() => history.push('/404err'));
};

export const sessionUpdateAction = (
  sessionData,
  id,
  history
) => async dispatch => {
  // const body = JSON.stringify(sessionData);

  dispatch({
    type: LOADING_TRUE,
    payload: 'sessionEditLoading'
  });

  axios
    .patch(`/api/session-edit/${id}`, sessionData)
    .then(res => {
      Swal.fire({
        title: 'Success',
        text: 'Session has been successfully edited',
        type: 'success',
        confirmButtonText: 'Ok',
        ...customSwal
      });
      history.push(`/session-details/${id}`);

      dispatch({
        type: EDIT_SESSION_SUCCESS,
        payload: res.data
      });

      dispatch({
        type: LOADING_FALSE,
        payload: 'sessionEditLoading'
      });
    })
    .catch(() => {
      dispatch({
        type: LOADING_FALSE,
        payload: 'sessionEditLoading'
      });
      history.push('/404err');
    });
};

// export const updateEmails = (id, participantsEmails) => async dispatch => {
//   axios
//     .patch(`/api/emails-update/${id}`, { participantsEmails })
//     .then(res => {
//       dispatch(fetchSessionDetails(id));
//       return dispatch({
//         type: UPDATE_EMAILS_SUCCESS,
//         payload: res.data
//       });
//     })
//     .catch(() => history.push('/404err'));
// };

export const sendEmails = (
  { surveyURL, participantsList, surveyType },
  history
) => async dispatch => {
  axios
    .post('/api/survey/email', {
      surveyURL,
      participantsList,
      surveyType
    })
    .then(res => {
      message.success('Done, emails sent successfully!');
      return dispatch({
        type: SEND_SURVEY_EMAIL_SUCCESS,
        payload: res.data
      });
    })
    .catch(() => {
      message.error('Error! Something went wrong');
      history.push('/404err');
    });
};

export const fetchParticipantSessions = () => async dispatch => {
  try {
    let res;
    res = await axios.get(`/api/participant/progress`);
    return dispatch({
      type: FETCH_PARTICIPANT_SESSIONS_BY_EMAIL_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    return message.error('Error! Something went wrong');
  }
};
