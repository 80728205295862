/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Empty, Pagination } from 'antd';

// COMMON COMPONENTS
import SessionList from '../../common/List/SessionList';
import Layout from '../../Layouts';
import Toggle from '../../common/Toggle';
import * as T from '../../common/Typography';
import Button from '../../common/Button';
import { Row, Col } from '../../common/Grid';
import CopyLink from '../../common/CopyLink';

// CONSTANTS
import {
  CREATE_SESSION,
  TRAINER_PUBLIC_SESSIONS
} from '../../../constants/navigationRoutes';

// ACTIONS
import {
  fetchTrainerSessions,
  fetchLocalLeadSessions,
  fetchALLSessions
} from '../../../actions/groupSessionsAction';

// STYLING
import { ViewSessionsWrapper, TotalSessions } from './ViewSessions.Style';

class ViewSessions extends Component {
  state = {
    headerTitle: null,
    toggle: 'left',
    localLeadOwnSession: null,
    page: 1
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { path: oldPath } = prevProps.match;
    const { path } = this.props.match;

    if (path !== oldPath) {
      this.setState({ page: 1, toggle: 'left' }, () => {
        if (prevState.toggle === 'left' && prevState.page === 1) {
          this.getData();
        }
      });
    }

    if (
      this.state.toggle !== prevState.toggle ||
      this.state.page !== prevState.page
    ) {
      this.getData();
    }
  }

  getData = () => {
    const { userId, match, role } = this.props;
    const { localLeadId, trainerId } = match.params;
    let resultsFor;
    let resultForRole;
    let headerTitle;
    this.setState({ localLeadOwnSession: '' });
    // admin || local lead || trainer viewing his own session
    if (match && match.path === '/my-sessions') {
      resultsFor = userId;
      resultForRole = 'trainer';
      headerTitle = 'Your Sessions';
      if (role === 'localLead') {
        this.setState({
          localLeadOwnSession:
            'Please note that this will only show training events that you are a trainer for, not the trainers you manage.'
        });
      }
      this.fetchSessionsData(resultForRole, resultsFor);
    } else if (
      (match &&
        match.path === '/group-results/:trainerId?' &&
        role === 'admin') ||
      role === 'localLead'
    ) {
      if (localLeadId) {
        resultsFor = localLeadId;
        resultForRole = 'localLead';
        headerTitle = 'Group Sessions';
        this.fetchSessionsData(resultForRole, resultsFor);
      } else {
        resultsFor = userId;
        resultForRole = 'localLead';
        headerTitle = 'Your Group Sessions';
        this.fetchSessionsData(resultForRole, resultsFor);
      }
    } else if (match && match.path === '/all-sessions') {
      resultsFor = userId;
      resultForRole = 'admin';
      headerTitle = 'All Sessions';

      this.fetchSessionsData(resultForRole, resultsFor);
    } else if (match && match.path === '/trainer-sessions/trainerId?') {
      if (trainerId) {
        resultsFor = trainerId;
        resultForRole = 'trainer';
        headerTitle = 'Trainer Sessions';

        this.fetchSessionsData(resultForRole, resultsFor);
      }
    }
    this.setState({ headerTitle, resultForRole });
  };

  fetchSessionsData = (role, id) => {
    const type = this.state.toggle === 'left' ? 'upcoming' : 'all';

    const filters = {
      type,
      page: this.state.page,
      sortAscending: type === 'upcoming'
    };

    if (role === 'admin') {
      this.props.fetchALLSessions({ history: this.props.history, ...filters });
    } else if (role === 'trainer') {
      this.props.fetchTrainerSessions({
        id,
        history: this.props.history,
        ...filters
      });
    } else if (role === 'localLead') {
      this.props.fetchLocalLeadSessions({
        id,
        history: this.props.history,
        ...filters
      });
    }
  };

  clickToggle = direction => {
    this.setState({ toggle: direction, page: 1 });
  };

  onChange = p => {
    this.setState({ page: p });
  };
  render() {
    const {
      headerTitle,
      toggle,
      resultForRole,
      localLeadOwnSession
    } = this.state;

    const { sessions, userId, role } = this.props;
    const localLeadGroupSessions =
      resultForRole === 'localLead' && !localLeadOwnSession;
    return (
      <Layout>
        <ViewSessionsWrapper>
          <Row mb="4">
            <Col w={[4, 8, 8]} display="block">
              <T.H1 mb="6" width="100%">
                {headerTitle}
              </T.H1>
              <TotalSessions>
                <T.P weight={700} mr="1">
                  {toggle === 'left' ? 'Upcoming Sessions:' : 'Total Sessions:'}
                </T.P>
                <T.P>{this.props.totalCount || 0}</T.P>
              </TotalSessions>
            </Col>
          </Row>
          <Row mb="8">
            <Col w={[4, 6, 4]}>
              <Button
                label="Add new session"
                type="primary"
                to={CREATE_SESSION}
                link
              />
            </Col>
          </Row>
          {role !== 'admin' && (
            <Row mb="8">
              <Col w={[4, 8, 8]} direction="column" jc="flex-start">
                <T.P weight={700} mb={4}>
                  Invite participants to your{' '}
                  {localLeadGroupSessions ? "group's" : ''} sessions
                </T.P>
                <T.P mb={4}>
                  Copy the link below to give participants the chance to choose
                  which of your {localLeadGroupSessions ? "group's" : ''}{' '}
                  sessions they wish to attend. Or select a session below if you
                  want to invite participants to a particular session.{' '}
                </T.P>
                {localLeadOwnSession && (
                  <T.P mb={4} mt={4}>
                    {localLeadOwnSession}
                  </T.P>
                )}
                <CopyLink
                  link={`${
                    process.env.NODE_ENV === 'production'
                      ? 'https://'
                      : 'http://'
                  }${window.location.host}${TRAINER_PUBLIC_SESSIONS.replace(
                    ':id',
                    userId
                  )}${localLeadGroupSessions ? `?group=true` : ''}`}
                />
              </Col>
            </Row>
          )}
          <Row mb="6">
            <Col w={[4, 6, 6]}>
              <Toggle
                large
                rightText="All Sessions"
                leftText="Upcoming sessions"
                selected={toggle}
                onClick={this.clickToggle}
              />
            </Col>
          </Row>

          <Row>
            <Col w={[4, 8, 8]} jc="center" mb={5}>
              <Pagination
                simple
                total={this.props.totalCount}
                onChange={this.onChange}
                disabled={this.props.loading}
                defaultCurrent={1}
                current={this.state.page}
              />
            </Col>
            <Col w={[4, 8, 8]}>
              {sessions && sessions.length ? (
                <SessionList dataList={this.props.loading ? null : sessions} />
              ) : (
                <Empty
                  description="No Sessions"
                  style={{ marginTop: '3rem' }}
                />
              )}
            </Col>
          </Row>
        </ViewSessionsWrapper>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    role: state.auth.role,
    sessions: state.sessions.sessions,
    totalCount: state.sessions.totalCount,
    lastType: state.sessions.lastType,
    loading: state.loading.fetchSessions
  };
};

export default connect(mapStateToProps, {
  fetchTrainerSessions,
  fetchLocalLeadSessions,
  fetchALLSessions
})(ViewSessions);
